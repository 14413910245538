import React from "react"
import Layout from "../components/Layout/layout"
import Maps from "../components/About/maps"
import TeamMembersTeaser from "../components/Teaser/wir"
import TestimonialsTeaser from "../components/Teaser/testimonials"
import ServiceTeaser from "../components/Teaser/service"

const ThirdPage = () => (
  <Layout
  heroURL="https://images.ctfassets.net/wm9p0wxrtkh3/71GxIU2jgIa4KRRjacAo78/3aae67ffc650012f242b69062870386c/buero_header.jpg"
  slug = 'kreuzfahrten'>

  <div class="notification is-white" style={{opacity: "85%", position: "absolute", right: "1rem", top:"5rem", left: "1rem"}}>
  <section class="section no-padding"div="funnel">
  <h1 class="subtitle has-text-weight-bold has-text-primary">Travel Connection Langenfeld - Das Reisebüro für Kreuzfahrten</h1>
  <h2 class="has-text-weight-normal has-text-grey">
  Ob Karibik, Nil oder Donau - Unsere <b>Kreuzfahrt Expertinnen</b> kennen alle Schiffe und finden die beste Kreufahrt für Sie.</h2>
  <br/>
  <h2 class="has-text-weight-normal has-text-grey">Wir besichtigen neue Schiffe persönlich und planen Reisen mit <b>TUI Mein Schiff, AIDA, MSC, Hurtigruten & mehr</b>.</h2>
  <br/>
  <h2 class="has-text-weight-normal has-text-grey">Unsere erfahrenen Kreuzfahrt Expertinnen beraten Sie <b>kostenlos und unverbindlich</b>.</h2>
  <br/>
  <h2 class="has-text-weight-normal has-text-grey">
  Jetzt <b>Frühbucher Angebote 2020</b> sichern. Einfach anrufen oder Termin vereinbaren!
  </h2>
    <br/>

  <button style={{width: "100%"}} type="submit" href="#bsd-home" class="birdseed-link button has-text-weight-normal is-primary is-medium">
  Expertinnen kontaktieren
  </button>
  </section>
  </div>

  <TestimonialsTeaser convert="true"/>
  <TeamMembersTeaser convert="true"/>
  <ServiceTeaser convert="true"/>
  <Maps/>
  </Layout>
)

export default ThirdPage
